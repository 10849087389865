






import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniSquareButton from '@/components/buttons/SygniSquareButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniMultiSelect from '@/components/inputs/SygniMultiSelect.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import WhistleblowerFilters from '../components/WhistleblowerFilters.vue';
import WhistleblowerTable from '../components/WhistleblowerTable.vue';
import SygniTabs from '@/components/layout/SygniTabs.vue';
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import {mapState} from "vuex";
import CaseModal from '../components/CaseModal.vue';
import SettingsPanel from '../components/SettingsPanel.vue';
import CategoriesPanel from '../components/CategoriesPanel.vue';

import CreateCaseModal from '../components/CreateCaseModal.vue';
import { LegalEntityData } from '../store/types';
import SygniAccessModal from '@/components/layout/SygniAccessModal.vue';

@Component({
  components: { SygniRoundedButton, SygniSquareButton, SygniLinkButton, SygniInput, SygniDatePicker, SygniSelect, SygniMultiSelect, SygniButtonsGroup, SygniCheckableButton, WhistleblowerFilters, WhistleblowerTable, SygniTabs, CaseModal, CreateCaseModal, SettingsPanel, CategoriesPanel, SygniAccessModal },
  computed: mapState('whistleblower', {
    casesTable: (state: any) => state.casesTable,
    perPage: (state: any) => state.casesTable.perPage,
    legalEntityData: (state: any) => state.legalEntityData
  })
})
export default class whistleblowerModule extends Vue {
  showInstructions: boolean = true;
  perPageOption?: number = 10;
  casesTable!: TableData<any>;
  showCaseModal: boolean = false;
  showCreateCaseModal: boolean = false;
  fundToken: string = '';
  legalEntityData!: LegalEntityData;

  tabs: Array<any> = [{ key: 'cases', label: 'Cases', }, { key: 'settings', label: 'Settings', }, { key: 'categories', label: 'Categories' }];

  toggleInstructions(): void {
    this.showInstructions = !this.showInstructions;
  }

  copyFundToken() {
    this.$copyText(this.fundToken).then(() => {
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Text copied'
      });
    }, () => {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'error',
        text: 'Text cannot be copied'
      });
    });
  }

  get instructionsText(): string {
    return this.showInstructions ? 'Hide instructions' : 'Show instructions';
  }

  async loadLegalEntityData() {
    const response = await this.$store.dispatch('whistleblower/getLegalEntityData');

    this.fundToken = response.token;
  }

  async beforeMount() {
    await this.loadLegalEntityData();
  }
}
